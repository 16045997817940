


























import Vue from "vue";
import { mapGetters } from "vuex";
import {
  ApiException,
  WebinarsSummaryPublicResponse,
  WebinarsUserClient,
} from "@/clients/clients";
import { loginRequest } from "@/configs/authConfig";
import WebinarPromotionCard from "@/components/webinars/WebinarPromotionCard.vue";
import AppPage from "@/components/layout/page/AppPage.vue";

export default Vue.extend({
  name: "WebinarCollectionView",
  metaInfo: {
    title: "My library",
  },
  components: {
    AppPage,
    WebinarPromotionCard,
  },
  data: () => ({
    errorMessage: "",
    loading: false,
    collection: [] as WebinarsSummaryPublicResponse[],
  }),
  computed: {
    ...mapGetters("UserStore", ["webinars"]),
  },
  async created() {
    this.loading = true;
    this.errorMessage = "";
    try {
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const userClient = this.$httpClientFactory(
        WebinarsUserClient,
        token.accessToken
      );
      const response = await userClient.batch(this.webinars);
      this.collection = response.webinars ?? [];
    } catch (e) {
      this.errorMessage =
        (e as ApiException).message ?? "Unknown error occurred";
    } finally {
      this.loading = false;
    }
  },
});
